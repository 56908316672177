.message-item {
  display: flex;
  align-items: center; /* Alinea los elementos en la misma línea */
  justify-content: space-between; /* Coloca el mensaje y la hora en los lados opuestos */
  max-width: 70%;
  margin: 10px 15px;
}

.message-item.other {
  flex-direction: row; /* Mantener la dirección estándar para mensajes del otro usuario */
  align-self: flex-start; /* Alinear al lado izquierdo */
}

.message-item.me {
  flex-direction: row-reverse; /* Para que el mensaje esté a la derecha y la hora a la izquierda */
  margin-left: auto; /* Mueve los mensajes hacia la derecha */
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end; /* Mensajes del usuario actual a la derecha */
  align-self: flex-end; /* Alinear el contenedor del mensaje al lado derecho del chat */
}

.message-content {
  background-color: #e0e0e0; /* Color para los mensajes del otro usuario */
  padding: 10px;
  border-radius: 10px;
  max-width: 100%;
  margin-bottom: 5px;
}

.message-item.me .message-content {
  background-color: #007bff; /* Cambia a tu color preferido para tus mensajes */
  color: #ffffff;
}

.message-timestamp {
  font-size: 0.8em;
  margin: 0 10px; /* Espacio entre la hora y el mensaje */
  white-space: nowrap; /* Evitar que la hora se desborde a otra línea */
  color: #555; /* Color de la hora */
}