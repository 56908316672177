.card-container {
  display: flex;
  flex-wrap: wrap; /* Permite que las tarjetas se ajusten a la siguiente fila */
  gap: 15px; /* Espacio entre tarjetas */
  padding: 10px;
  justify-content: flex-start; /* Alinea las tarjetas hacia la izquierda */
  margin-top: 0px; /* Mueve las tarjetas hacia abajo debajo de "Mis Tarjetas" */
}



/* Estilo general de las tarjetas */
.custom-card {
  width: 200px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease-in-out;
  color: white;
}

.custom-card:hover {
  transform: scale(1.02);
}

/* Gradiente oscuro en la tarjeta */
.custom-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  z-index: 1;
}

/* Contenido de la tarjeta */
.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

/* Tipo de tarjeta */
.card-type {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #2196F3;
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
}

.card-type.home {
  background-color: #ff5ecc;
  color: #333;
}

.card-type.outside {
  background-color: #2196f3;
  color: #fff;
}

/* Fecha de la tarjeta */
.card-date {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
}

/* Título dentro de las tarjetas */
.card-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Estilos de la imagen de perfil y datos */
.profile-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.profile-picture {
  width: 300px;
  height: 300px;
  border-radius: 50%; /* Hace que la imagen sea circular */
  object-fit: cover; /* Ajusta la imagen para evitar deformaciones */
  border: 5px solid #2196F3; /* Borde azul alrededor */
}

.profile-name {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}


.profile-address {
  text-align: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Contenedor para la foto de perfil y el botón de edición */
.profile-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

/* Estilos para la imagen de perfil */
.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%; /* Hace que la imagen sea circular */
  object-fit: cover; /* Ajusta la imagen para evitar deformaciones */
  border: 3px solid #030303; /* Borde alrededor */
}

.edit-button {
  position: absolute;
  top: 10px; /* Separación desde el borde superior */
  right: 650px; /* Separación desde el borde derecho */
  --ion-background-color: #2196F3; /* Color del botón */
}

.ion-card-custom {
  width: 500px; /* O el valor que necesites para hacerla más estrecha */
}
