.card-container {
  margin-left: 65px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 10px;
  justify-content: center;
}

.custom-card {
  width: 200px; /* Ajusta el tamaño según tus necesidades */
  height: 300px; /* Ajusta el alto según tus necesidades */
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease-in-out;
  color: white; /* Color de texto para mayor contraste */
}

.custom-card:hover {
  transform: scale(1.02);
}

.custom-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  z-index: 1;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}

.card-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.9em;
  padding: 10px;
}

.custom-card {
  position: relative;
  width: 200px; /* Ajusta según tus necesidades */
  height: 300px; /* Ajusta según tus necesidades */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease-in-out;
}

.card-date {
  position: absolute;
  top: 0; /* Fija la posición en la parte superior */
  right: 0; /* Fija la posición en el borde derecho */
  margin: 8px; /* Ajusta el espacio desde los bordes si es necesario */
  background-color: #4CAF50;
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  z-index: 2; /* Asegura que esté por encima de otros elementos */
}


.card-type {
  position: absolute;
  top: 8px; /* Ajuste para la parte superior */
  left: 8px; /* Fija la posición en el borde izquierdo */
  background-color: #2196F3;
  color: white;
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  z-index: 2;
}
.card-type.home {
  background-color: #ff5ecc;
  color: #333;
}

.card-type.outside {
  background-color: #2196f3;
  color: #fff;
}

.card-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 5px;
}


  
.unread-bubble {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
}

.unread-indicator {
  position: absolute;
  top: 50%;
  right: 10px; /* Ajustar según el diseño */
  transform: translateY(-50%);
  background-color: blue;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
}

